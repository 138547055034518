body {
  padding: 0px;
  margin: 0px;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 14px;
  background-color: #f1f1f1;
  box-sizing: border-box;
}

.header {
  width: 100%;
  height: 48px;
  background-color: #0281ff;
  color: white;
}
.inside-header {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 10px;
}

.arrow {
  margin-top: 4px;
  padding-right: 5px;
}

.input {
  border: 1px solid whitesmoke;
  margin-bottom: 20px;
  border-radius: 30px;
  height: 48px;
  padding-left: 25px;
}

.inputs {
  display: flex;
  flex-direction: column;
}

.button {
  font-size: 18px;
  height: 46px;
  border-radius: 2400px;
  border: none;
  background-color: #ffffff;
  padding: 13px;
  cursor: pointer;
}

.login-parent {
  display: flex;
  width: 100%;
  justify-content: center;
}

.login {
  background-color: #0281ff;
  color: white;
  width: 192px;
  border-radius: 5px;
}

.bottom-button {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.myForm {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.myInput {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.myInput svg,
.myInputImage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  color: #999;
}
.myInput input {
  padding: 1rem;
  background-color: #fff;
  outline: none;
  border: none;
  border-radius: 25px;
  padding-left: 1.9rem;
}

.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  height: 2.4rem;
  align-items: center;
  padding: 0 1rem;
  width: 100vw;
}
.footer-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  font-size: 10px;
  color: #aaaaaa;
}

.blue {
  color: #3aa0ff;
  /* font-weight: 600; */
}

.invisText{
  color: #f1f1f1;
}
